import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { push as Menu } from 'react-burger-menu';
import UserContext from '../context/UserContext';
import { useNavigate } from 'react-router-dom';

const MenuHamburguer = ({ isOpen, toggleMenu, toggleModal }) => {
    const { logout } = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();
    const getActiveClass = (path) => {
        return location.pathname === path ? 'active' : '';
      };

      const handleLogout = () => {
        logout(); // Llama a la función de cierre de sesión
        navigate('/login'); // Redirige al login después de cerrar sesión
      };
      

    return (
        <Menu right isOpen={isOpen} className="w-layout-vflex container-menu-hamburguer" width={'400px'}>
            <img src="/hand-riffapp.png" loading="lazy" alt="" className="logo-menu" onClick={toggleMenu} />
            <Link to="/home-log-in">
                <div  className={`w-layout-hflex align-center gap-24 opc-menu ${getActiveClass('/home-log-in')}`}>
                    <div className="w-embed icon-menu-login">
                        <svg width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_862_19601)">
                                <path d="M32.5 31.2904C32.5 31.6637 32.3478 32.0217 32.0769 32.2856C31.806 32.5496 31.4386 32.6978 31.0556 32.6978H7.94444C7.56135 32.6978 7.19395 32.5496 6.92307 32.2856C6.65218 32.0217 6.5 31.6637 6.5 31.2904V16.4986C6.49985 16.2841 6.55 16.0724 6.64663 15.8797C6.74327 15.687 6.88382 15.5184 7.05756 15.3867L18.6131 6.62985C18.8667 6.43766 19.1788 6.33331 19.5 6.33331C19.8212 6.33331 20.1333 6.43766 20.3869 6.62985L31.9424 15.3867C32.1162 15.5184 32.2567 15.687 32.3534 15.8797C32.45 16.0724 32.5002 16.2841 32.5 16.4986V31.2904ZM29.6111 29.883V17.1854L19.5 9.52347L9.38889 17.1854V29.883H29.6111ZM12.2778 24.2534H26.7222V27.0682H12.2778V24.2534Z" fill="currentColor"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_862_19601">
                                    <rect width="39" height="38" fill="white"></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="text-menu-login">Inicio</div>
                </div>
            </Link>
            <Link to="/user-profile">
                <div className={`w-layout-hflex align-center gap-24 opc-menu ${getActiveClass('/user-profile')}`}>
                    <div className="w-embed icon-menu-login">
                        <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_862_19634)">
                                <path d="M29.1281 31.7428H26.7454V29.36C26.7454 28.4121 26.3689 27.5031 25.6986 26.8328C25.0283 26.1625 24.1192 25.786 23.1713 25.786H16.0232C15.0753 25.786 14.1662 26.1625 13.4959 26.8328C12.8257 27.5031 12.4491 28.4121 12.4491 29.36V31.7428H10.0664V29.36C10.0664 27.7802 10.694 26.2651 11.8111 25.148C12.9282 24.0308 14.4434 23.4033 16.0232 23.4033H23.1713C24.7512 23.4033 26.2663 24.0308 27.3834 25.148C28.5005 26.2651 29.1281 27.7802 29.1281 29.36V31.7428ZM19.5973 21.0205C18.6586 21.0205 17.729 20.8356 16.8618 20.4764C15.9945 20.1172 15.2065 19.5907 14.5428 18.9269C13.879 18.2631 13.3525 17.4751 12.9932 16.6079C12.634 15.7406 12.4491 14.8111 12.4491 13.8724C12.4491 12.9337 12.634 12.0042 12.9932 11.1369C13.3525 10.2697 13.879 9.48165 14.5428 8.81789C15.2065 8.15412 15.9945 7.62759 16.8618 7.26836C17.729 6.90914 18.6586 6.72424 19.5973 6.72424C21.4931 6.72424 23.3112 7.47735 24.6518 8.81789C25.9923 10.1584 26.7454 11.9766 26.7454 13.8724C26.7454 15.7682 25.9923 17.5864 24.6518 18.9269C23.3112 20.2674 21.4931 21.0205 19.5973 21.0205ZM19.5973 18.6378C20.8611 18.6378 22.0732 18.1358 22.9669 17.2421C23.8606 16.3484 24.3627 15.1363 24.3627 13.8724C24.3627 12.6085 23.8606 11.3964 22.9669 10.5027C22.0732 9.60903 20.8611 9.10696 19.5973 9.10696C18.3334 9.10696 17.1213 9.60903 16.2276 10.5027C15.3339 11.3964 14.8318 12.6085 14.8318 13.8724C14.8318 15.1363 15.3339 16.3484 16.2276 17.2421C17.1213 18.1358 18.3334 18.6378 19.5973 18.6378Z" fill="currentColor"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_862_19634">
                                    <rect width="38.1235" height="38.1235" fill="white" transform="translate(0.535156 0.370361)"></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="text-menu-login">Perfil</div>
                </div>
            </Link>
            <Link to="/my-raffles">
                <div className={`w-layout-hflex align-center gap-24 opc-menu ${getActiveClass('/my-raffles')}`}>
                    <div className="w-embed icon-menu-login">
                        <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_862_19607)">
                                <path d="M11.2578 6.46512H27.9368C28.2527 6.46512 28.5558 6.59064 28.7792 6.81406C29.0026 7.03748 29.1281 7.34051 29.1281 7.65648V30.4626C29.1283 30.5692 29.0998 30.6738 29.0458 30.7656C28.9918 30.8573 28.9141 30.933 28.8209 30.9846C28.7277 31.0361 28.6224 31.0618 28.516 31.0588C28.4095 31.0559 28.3057 31.0244 28.2156 30.9678L19.5973 25.5626L10.979 30.9666C10.8889 31.0232 10.7853 31.0546 10.6789 31.0576C10.5725 31.0606 10.4673 31.0351 10.3741 30.9837C10.281 30.9322 10.2033 30.8568 10.1492 30.7651C10.0951 30.6735 10.0665 30.5691 10.0664 30.4626V7.65648C10.0664 7.34051 10.1919 7.03748 10.4153 6.81406C10.6388 6.59064 10.9418 6.46512 11.2578 6.46512ZM26.7454 8.84783H12.4491V27.2329L19.5973 22.7522L26.7454 27.2329V8.84783Z" fill="currentColor"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_862_19607">
                                    <rect width="38.1235" height="38.1235" fill="white" transform="translate(0.535156 0.111206)"></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="text-menu-login">Mis rifas</div>
                </div>
            </Link>
            <div className="line"></div>
            {/* <Link to="/">
                <div className="w-layout-hflex align-center gap-24 opc-menu">
                    <div className="w-embed icon-menu-login">
                        <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.6988 15.9841C26.6988 13.8679 25.8718 11.8383 24.3999 10.3419C22.9279 8.84547 20.9315 8.00479 18.8498 8.00479C16.7681 8.00479 14.7717 8.84547 13.2998 10.3419C11.8278 11.8383 11.0009 13.8679 11.0009 15.9841V26.6232H26.6988V15.9841ZM29.3151 27.5103L29.8383 28.2191C29.9112 28.3179 29.9556 28.4354 29.9665 28.5583C29.9774 28.6813 29.9544 28.805 29.9001 28.9154C29.8458 29.0259 29.7623 29.1188 29.6589 29.1837C29.5556 29.2486 29.4365 29.283 29.3151 29.283H8.38455C8.26308 29.283 8.14401 29.2486 8.04068 29.1837C7.93735 29.1188 7.85385 29.0259 7.79952 28.9154C7.7452 28.805 7.7222 28.6813 7.73311 28.5583C7.74402 28.4354 7.7884 28.3179 7.86128 28.2191L8.38455 27.5103V15.9841C8.38455 13.1625 9.48714 10.4564 11.4498 8.46114C13.4124 6.46592 16.0743 5.34502 18.8498 5.34502C21.6254 5.34502 24.2873 6.46592 26.2499 8.46114C28.2125 10.4564 29.3151 13.1625 29.3151 15.9841V27.5103ZM15.5794 30.6129H22.1202C22.1202 31.4947 21.7756 32.3403 21.1623 32.9638C20.549 33.5873 19.7172 33.9376 18.8498 33.9376C17.9824 33.9376 17.1506 33.5873 16.5373 32.9638C15.924 32.3403 15.5794 31.4947 15.5794 30.6129Z" fill="currentColor"></path>
                            <circle cx="25.951" cy="8.00828" r="4.76543" fill="#FA0E0E"></circle>
                        </svg>
                    </div>
                    <div className="text-menu-login">Guardado</div>
                </div>
            </Link> */}
            <Link to="#">
                <div className={`w-layout-hflex align-center gap-24 opc-menu ${getActiveClass('/')}`}
                    onClick={toggleModal}>
                    <div className="w-embed icon-menu-login">
                        <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_862_19622)">
                                <path d="M25.3155 13.3637V9.07476L29.6044 4.78587L31.0341 7.64513L33.8933 9.07476L29.6044 13.3637H25.3155ZM25.3155 13.3637L19.597 19.0821M33.8934 19.0822C33.8934 26.9778 27.4927 33.3785 19.5971 33.3785C11.7015 33.3785 5.30078 26.9778 5.30078 19.0822C5.30078 11.1865 11.7015 4.78587 19.5971 4.78587M26.7452 19.0822C26.7452 23.03 23.5449 26.2303 19.5971 26.2303C15.6493 26.2303 12.4489 23.03 12.4489 19.0822C12.4489 15.1344 15.6493 11.934 19.5971 11.934" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <circle cx="33.8934" cy="4.78588" r="4.76543" fill="#FA0E0E"></circle>
                            </g>
                            <defs>
                                <clipPath id="clip0_862_19622">
                                    <rect width="38.1235" height="38.1235" fill="white" transform="translate(0.535156 0.0204468)"></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="text-menu-login">Notificaciones</div>
                </div>
            </Link>
            
            <div className="line"></div>
           
            <Link to="/help">
                <div className={`w-layout-hflex align-center gap-24 opc-menu ${getActiveClass('/')}`}>
                    <div className="w-embed icon-menu-login">
                        <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_862_19638)">
                                <path d="M19.5965 32.5308C12.578 32.5308 6.88867 26.8415 6.88867 19.823C6.88867 12.8044 12.578 7.11514 19.5965 7.11514C26.615 7.11514 32.3043 12.8044 32.3043 19.823C32.3043 26.8415 26.615 32.5308 19.5965 32.5308ZM19.5965 29.9892C22.2927 29.9892 24.8786 28.9181 26.7851 27.0116C28.6917 25.105 29.7627 22.5192 29.7627 19.823C29.7627 17.1267 28.6917 14.5409 26.7851 12.6343C24.8786 10.7278 22.2927 9.65671 19.5965 9.65671C16.9002 9.65671 14.3144 10.7278 12.4079 12.6343C10.5013 14.5409 9.43024 17.1267 9.43024 19.823C9.43024 22.5192 10.5013 25.105 12.4079 27.0116C14.3144 28.9181 16.9002 29.9892 19.5965 29.9892ZM18.3257 23.6353H20.8673V26.1769H18.3257V23.6353ZM20.8673 21.5449V22.3645H18.3257V20.4584C18.3257 20.1213 18.4596 19.7981 18.6979 19.5598C18.9362 19.3215 19.2595 19.1876 19.5965 19.1876C19.9575 19.1875 20.3111 19.085 20.6161 18.8919C20.9211 18.6988 21.165 18.423 21.3194 18.0967C21.4738 17.7704 21.5324 17.4069 21.4883 17.0486C21.4442 16.6903 21.2993 16.3519 21.0704 16.0728C20.8415 15.7936 20.538 15.5852 20.1953 15.4718C19.8526 15.3583 19.4847 15.3446 19.1345 15.432C18.7842 15.5195 18.466 15.7047 18.2169 15.9659C17.9678 16.2272 17.7979 16.5538 17.7272 16.9078L15.2339 16.4084C15.3885 15.6358 15.7454 14.9182 16.2682 14.3288C16.791 13.7395 17.461 13.2995 18.2095 13.0539C18.9581 12.8083 19.7585 12.7659 20.5288 12.931C21.2992 13.0961 22.0119 13.4628 22.594 13.9937C23.1762 14.5245 23.607 15.2004 23.8423 15.9522C24.0777 16.7041 24.1091 17.505 23.9335 18.273C23.7579 19.041 23.3815 19.7486 22.8427 20.3235C22.304 20.8983 21.6223 21.3198 20.8673 21.5449Z" fill="currentColor"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_862_19638">
                                    <rect width="38.1235" height="38.1235" fill="white" transform="translate(0.535156 0.76123)"></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="text-menu-login">Ayuda</div>
                </div>
            </Link>
            <div onClick={handleLogout} className='pointer'>
                <div className={`w-layout-hflex align-center gap-24 opc-menu ${getActiveClass('/')}`}>
                    <div className="w-embed icon-menu-login">
                        <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_862_19643)">
                                <path d="M12.3086 9.36628L13.7661 11.4491C11.9949 12.6891 10.6651 14.4615 9.96999 16.5088C9.27485 18.5562 9.25055 20.7718 9.90062 22.8339C10.5507 24.896 11.8413 26.6972 13.5849 27.9757C15.3285 29.2543 17.4343 29.9436 19.5965 29.9436C21.7586 29.9436 23.8645 29.2543 25.6081 27.9757C27.3517 26.6972 28.6423 24.896 29.2924 22.8339C29.9424 20.7718 29.9181 18.5562 29.223 16.5088C28.5278 14.4615 27.1981 12.6891 25.4268 11.4491L26.8844 9.36628C28.5592 10.5372 29.9266 12.0952 30.8702 13.9079C31.8138 15.7205 32.3058 17.7342 32.3043 19.7778C32.3043 26.7963 26.615 32.4856 19.5965 32.4856C12.578 32.4856 6.88868 26.7963 6.88868 19.7778C6.8872 17.7342 7.37918 15.7205 8.32279 13.9079C9.2664 12.0952 10.6337 10.5372 12.3086 9.36628ZM18.3257 19.7778V7.06998H20.8673V19.7778H18.3257Z" fill="currentColor"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_862_19643">
                                    <rect width="38.1235" height="38.1235" fill="white" transform="translate(0.535156 0.716064)"></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="text-menu-login">Cerrar sesión</div>
                </div>
            </div>
        </Menu>
    );
};



export default MenuHamburguer;


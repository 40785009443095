import React, { useState, useEffect } from 'react';
import axios from 'axios';

const NotificationsModal = ({ closeModal }) => {

  const [notifications, setNotificacions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const token = localStorage.getItem('token');
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          `${apiURL}notifications-custom/`, {
          params: {
            page: 1
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
        });

        setNotificacions(response.data.results);
        console.log(response.data.results);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  return (
    <div className="modal bg-modal bg-transparent">
      <div className="modal-content modal-noticaciones">
        <div className="w-layout-vflex gap-32 full-width notification">
          <h4 className="font-yellow">Notificaciones</h4>
          <div onClick={closeModal} className="close-btn notification" >
            <img
              loading="lazy"
              src="/btn-close-modal-ami.svg"
              alt="Cerrar"
            />
          </div>
        </div>
        <div className="line-gray-horizontal"></div>
        <div className="w-layout-vflex gap-24 full-width">
          {notifications.map((notification) => (
            <div key={notification.id} className="w-layout-vflex container-notification">
              <div className="w-layout-hflex gap-20 align-center">
                <img
                  src="/Avatar.png"
                  loading="lazy"
                  alt="Avatar"
                  className="img-notification"
                />
                <div className="font-small font-weight-500">
                  {new Date(notification.created_at).toLocaleString()}
                </div>
                {!notification.read &&
                  <div className="dot red"></div>
                }
              </div>
              <div className="font-graydark">
                {notification.message}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
};

export default NotificationsModal;

import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';


const PopupLinkReferral = ({link, onClose}) => {
    const [error, setError] = useState('');
    const [copiado, setCopiado] = useState(false);

    const ocultarMensaje = () => {
        setCopiado(false);
    };

    return (
        <div>
            <div className="bg-modal" >
                <div className="modal-signup">
                    <img src="/logo-riffapp.png" loading="lazy" alt="" className="logo-navbar centrado" />
                    <div className="w-layout-vflex gap-32 full-width">
                        <div className="close-btn ami" onClick={onClose}> 
                                <img loading="lazy" src="/btn-close-modal-ami.svg" alt="Close Button" />
                        </div>
                        <div className="w-layout-vflex gap-16">
                            <img loading="lazy" src="/icon-success.svg" alt="Success Icon" />
                            <div className="w-layout-vflex">
                                <h4 className="font-18 weight-600">Link de Riffapper:</h4>
                                <h4 className="font-18 weight-600">Rosa Maria</h4>
                                {link ? (
                                    <p>{link}</p> // Muestra el link
                                ) : (
                                    <p>Obteniendo link...</p> // Mensaje mientras se carga el link
                                )}

                            </div>
                        </div>
                        <CopyToClipboard text={link} onCopy={() => setCopiado(true)}>

                            <button className="btn-primary full-width w-button" disabled={!link}>Copiar en portapapeles</button>
                        </CopyToClipboard>
                        {copiado && (
                                <div id="copiado-exitoso" className="w-layout-hflex badge-orange">
                                    <div>Link copiado en el portapapeles</div>
                                    <a href="#" onClick={ocultarMensaje}>X</a> {/* Añadir funcionalidad para cerrar */}
                                </div>
                            )}
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PopupLinkReferral;

import React, { useEffect, useState } from 'react';
import NavbarLogout from './Navbarlogout'; // Navbar para usuarios no logueados
import NavbarLogin from './Navbarlogin'; // Navbar para usuarios logueados

const Navbar = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Estado para determinar si está logueado

    useEffect(() => {
        const checkToken = () => {
            const token = localStorage.getItem('token');
            setIsLoggedIn(!!token);
        };
    
        checkToken();
    
        window.addEventListener('storage', checkToken);
    
        return () => {
            window.removeEventListener('storage', checkToken); // Limpia el evento
        };
    }, []);

    return (
        <>
            {isLoggedIn ? <NavbarLogin /> : <NavbarLogout />}
        </>
    );
};

export default Navbar;

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ReferralsClients = () => {
  const [clients, setClients] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchClients = async () => {
      const token = localStorage.getItem('token');
      try {
        const headers = {
          Authorization: `Token ${token}`, // Autenticación con el token
        };
        // Hacer la petición a la API con Axios
        const response = await axios.get(`${apiURL}users/referrals/`, { headers });
        console.log('Respuesta de la API:', response.data);
        // Verifica si la respuesta de la API es un array
        if (Array.isArray(response.data)) {
          setClients(response.data);
        } else {
          console.error('La respuesta de la API no es un array:', response.data);
        }
      } catch (error) {
        console.error('Error al obtener los clientes:', error);
      }
    };
    fetchClients();
  }, [apiURL]);
  return (
    <div className="grid-clients-refeal">
      {clients.map((client, index) => (
        <div key={index} className="w-layout-hflex strech">
          <div className="w-layout-hflex gap-16">
            <img src='/Avatar.png' loading="lazy" alt="" className="img-client-refeal" />
            <div className="w-layout-vflex">
              <div className="font-small font-weight-500">{client.name}</div>
              <div className="font-small">{client.rifas} rifas</div>
            </div>
          </div>
          <div className="dot"></div>
        </div>
      ))}
    </div>
  );
};
export default ReferralsClients;
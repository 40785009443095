import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SearchBar = () => {
    const [query, setQuery] = useState('');
    const navigate = useNavigate();

    const handleSearch = () => {
        navigate(`/results?search=${query}#resultado`);
    };

    return (


        <div className="w-layout-vflex align-center gap-32">
            <img src="/logo-riffapp.png" loading="lazy" alt="" className="logo-banner" />
            <div className="w-form">
                <div className="container-search" >
                    <input
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        className="fields _w-786 w-input"
                        placeholder="Introduzca texto a buscar"
                        type="text"
                    />
                    <button className="btn-dark w-button" onClick={handleSearch}>Buscar</button>
                </div>
            </div>
        </div>


    );
};

export default SearchBar;

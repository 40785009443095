import React, { useState,useEffect } from "react";
import hmacSHA256 from "crypto-js/hmac-sha256";
import Hex from "crypto-js/enc-hex";
import axios from "axios";
import { set } from "date-fns";
import { fi } from "date-fns/locale";


export default function TasaBcv({totalMonto}) {
    const [tasa, setTasa] = useState(null); 
    const [totalBs, setTotalBs] = useState(null); // Total calculado en bolívares
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const getCurrentDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        console.log("Total Monto", totalMonto);
        consultarTasa();
      }, []);
    
    useEffect(() => {
      // Calcular el total en bolívares cuando la tasa cambia
      if (tasa && totalMonto) {
        const totalEnBs = (parseFloat(totalMonto) * parseFloat(tasa)).toFixed(2);
        setTotalBs(totalEnBs);
        localStorage.setItem('monto_bolivares', totalEnBs);
      }
    }, [tasa, totalMonto]);
    
    const consultarTasa = async () => {
        setLoading(true);
        const commerceId = process.env.REACT_APP_R4_COMMERCE_ID;
        const moneda = "USD";
        const fechaValor = getCurrentDate();
      
        const mensaje = `${fechaValor}${moneda}`;
        const token = hmacSHA256(mensaje, commerceId).toString(Hex);
      
        const url = process.env.REACT_APP_R4_CONNECT;
        const headers = {
          "Content-Type": "application/json",
          Authorization: token,
          Commerce: commerceId,
        };
      
        const payload = {
          Moneda: moneda,
          Fechavalor: fechaValor,
        };
      
        try {
          const response = await axios.post(url, payload, { headers });
          const result = response.data;
      
          if (result.code === "00") {
            setTasa(result.tipocambio);
          } else {
            console.error(`Error en la consulta: ${result.code}`);
          }
        } catch (err) {
          console.error("Error en la consulta:", err.message);
        }
        finally {
          setLoading(false);
        }
      };
    return (
        <div>
        {loading && <p>Cargando tasa BCV...</p>}
        {error && <p style={{ color: "red" }}>Error: {error}</p>}
        {totalBs && (
          <div>
             Bs. {totalBs} 
          </div>
        )}
      </div>
    );
}



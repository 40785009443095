import React, { useState, useEffect } from 'react';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ConfigProfile = () => {
  const storedUserName = localStorage.getItem('username');
  const apiURL = process.env.REACT_APP_API_URL;
  const [error, setError] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [codigo, setCodigo] = useState('+58412');
  const userid = localStorage.getItem('userid');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [lastname, setLastname] = useState('');
  const [address, setAddress] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const token = localStorage.getItem('token');


  const fetchUserInfo = async () => {
    try {
      const headers = { Authorization: `Token ${token}` };
      // Verifica si el token es válido
      const response = await axios.get(`${apiURL}users/${userid}/`, { headers });
      if (response.status === 200) {
        setUsername(response.data.name || '');
        setEmail(response.data.email || '');
        setLastname(response.data.last_name || '');
        setAddress(response.data.address || '');
        const phoneData = response.data.phone_number;
        if (phoneData) {
          console.log('cargo aqui');
          
          // Usa una expresión regular para capturar el prefijo y el número
          const match = phoneData.match(/^\+58(\d{3})(\d+)$/);
        
          if (match) {
            console.log('cargo aqui otra vez');
            const prefix = match[1]; // Captura los 4 dígitos del prefijo
            const number = match[2]; // Captura el resto del número
        
            setCodigo(`+58${prefix}`); // Guarda el prefijo completo con +58
            setPhone(number); // Guarda el número restante
        
            // Ajusta la visualización en el select
            const selectValue = prefix.replace(/^58/, '0'); // Cambia +58 a 0 para mostrarlo
            console.log('Valor en el select:', selectValue);
          } else {
            console.log('El número no tiene un formato válido.');
          }
        }
        
      } else {
        setError('Error al obtener los datos del usuario.');
      }
    } catch (error) {
      console.error('Error al obtener los datos del usuario:', error);
      setError('Error al obtener los datos del usuario.');
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const telconcatenado = `${codigo}${phone}`;
    const payload = {};

    if (username) payload.name = username;
    if (email) payload.email = email;
    if (lastname) payload.last_name = lastname;
    if (address) payload.address = address;
    if (phone) payload.phone_number = telconcatenado;

    if (Object.keys(payload).length === 0) {
      setError('Por favor, completa al menos un campo para actualizar.');
      return;
    }

    try {
      const response = await axios.patch(
        `${apiURL}users/${userid}/`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          }
        }
      );

      console.log(response);

      if (response.status === 200) {
        localStorage.setItem('responseMessage', 'Información actualizada satisfactoriamente');
        if (payload.name) {
          localStorage.setItem('username', payload.name);
        }
        window.location.reload();
      } else {
        setError(`Error: ${response.status}`);
      }
    } catch (err) {
      console.error('Error al intentar autenticar:', err);
      setError('Error de red. Inténtalo de nuevo más tarde.');
    }
  };


  return (
    <div>
      <Navbar />
      <section>
        <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-120 w-container no-pd-bottom-mobile">
          <div className="w-layout-hflex align-center gap-24">
            <img
              src="/perfil.jpeg"
              loading="lazy"
              sizes="96px"
              alt=""
              className="img-perfil-nav userpage"
            />
            <div className="w-layout-vflex gap-8">
              <div className="font-18 weight-600">{storedUserName}</div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-32 w-container">
          <div className="w-layout-vflex gap-64">
            <h4>Configuración</h4>
            <div className="w-layout-vflex container-collapse-profile">
              <div className="w-layout-hflex gap-24 height-44">
                <div className="font-18">Información Personal</div>
                <img src="/chevron-down-1.svg" loading="lazy" alt="" />
              </div>
              <div className="full-width w-form">
                <form className="grid-edit-profile" onSubmit={handleSubmit}>
                  <div className="w-layout-vflex gap-10">
                    <label htmlFor="Nombre" className="font-small font-graydark">Nombre</label>
                    <input 
                      className="fields w-input" 
                      name="Nombre" 
                      placeholder="Nombre" 
                      type="text" 
                      value={username} 
                      onChange={(e) => setUsername(e.target.value)} 
                      disabled={!isEditing}/>
                  </div>
                  <div className="w-layout-vflex gap-10">
                    <label htmlFor="Apellido" className="font-small font-graydark">Apellido</label>
                    <input 
                      className="fields w-input" 
                      name="Apellido" 
                      placeholder="Apellido" 
                      type="text" 
                      value={lastname} 
                      onChange={(e) => setLastname(e.target.value)} 
                      disabled={!isEditing}/>
                  </div>
                  <div className="w-layout-vflex gap-10">
                    <label htmlFor="Email-log-in" className="font-small font-graydark">Email</label>
                    <input 
                      className="fields w-input" 
                      name="Email" 
                      placeholder="nombre@proveedor.com" 
                      type="email" 
                      value={email} 
                      onChange={(e) => setEmail(e.target.value)} 
                      disabled={!isEditing}/>
                  </div>
                  <div className="w-layout-vflex gap-10">
                    <label htmlFor="Email-sing-up" className="font-small font-graydark">Teléfono*</label>
                    <div className="w-layout-hflex full-width">
                      <select 
                        className="fields select-field left-field w-select" 
                        value={codigo} 
                        onChange={(e) => setCodigo(e.target.value)}
                        disabled={!isEditing}>
                        <option value="+58412">0412</option>
                        <option value="+58414">0414</option>
                        <option value="+58424">0424</option>
                        <option value="+58416">0416</option>
                        <option value="+58426">0426</option>
                      </select>

                      <input
                        className="fields w-input right-field"
                        placeholder="Ingresa tu número telefónico"
                        type="number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value.slice(0, 7))}
                        disabled={!isEditing} />
                    </div>
                  </div>
                  <div id="address-field" className="w-layout-vflex gap-10">
                    <label htmlFor="Direccion" className="font-small font-graydark">Dirección</label>
                    <input 
                      className="fields w-input" 
                      name="Direccion" 
                      placeholder="Estado, ciudad, calle..." 
                      type="text" 
                      value={address} 
                      onChange={(e) => setAddress(e.target.value)}
                      disabled={!isEditing} />
                  </div>
                  {!isEditing ? (
                    <button
                      type="button"
                      className="btn-primary absolute w-button"
                      onClick={() => setIsEditing(true)}
                    >
                      Editar
                    </button>
                  ) : (
                    <input
                      type="submit"
                      data-wait="Please wait..."
                      className="btn-primary absolute w-button"
                      value="Guardar"
                    />
                  )}
                </form>
                {responseMessage && (
                  <div className="message winner">
                    <div>{responseMessage}</div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-layout-vflex container-collapse-profile">
              <div className="w-layout-hflex full-width strech align-center">
                <div className="font-18">Opciones de Seguridad y Privacidad</div>
                <Link to={{ pathname: "/reset-password", state: { origin: "forgot" } }} className="btn-primary w-button">Editar contraseña</Link>
              </div>
            </div>
          </div>
        </div>
      </section>



      <Footer />


    </div>
  );
};

export default ConfigProfile;

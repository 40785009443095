import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

const Navbarlout = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="container-navbar w-nav">
      <div className="container navbar w-container">
        <RouterLink to="/" className="w-nav-brand">
          <img src="/logo-riffapp.png" loading="lazy" alt="Logo" className="logo-navbar" />
        </RouterLink>
        {/* Hamburger Button */}
        <div className="w-nav-button" onClick={toggleMenu}>
          <div className={`w-icon-nav-menu ${menuOpen ? 'open' : ''}`}></div>
        </div>
        {/* Navigation Menu */}
        <nav
          role="navigation"
          className={`nav-menu w-nav-menu ${menuOpen ? 'menu-open' : ''}`}
        >
          <div className="w-layout-hflex container-options-menu">
            <RouterLink to="/" className="nav-link w-nav-link" onClick={toggleMenu}>
              Inicio
            </RouterLink>
            <div className="w-layout-hflex container-options-menu">
              <ScrollLink
                to="raffles"
                smooth={true}
                duration={500}
                className="nav-link w-nav-link"
                onClick={toggleMenu}
              >
                Rifas
              </ScrollLink>
            </div>
          </div>
          <div className="w-layout-hflex container-options-menu">
            <RouterLink to="/login" className="nav-link w-nav-link" onClick={toggleMenu}>
              Ingresar
            </RouterLink>
            <RouterLink to="/signup" className="btn-primary w-button" onClick={toggleMenu}>
              Registrarte
            </RouterLink>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbarlout;


import React, { useState, useContext, useEffect } from 'react';
import { CopiadoContext } from '../components/Riffapper/CopiadoContext';
import Navbar from '../components/Navbar/Navbar';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados

import Footer from '../components/Footer';
import ReferralsClients from '../components/Riffapper/ListClientReferrals';
import { Link } from 'react-router-dom';
import axios from 'axios';
import CopyToClipboardLink from '../components/Riffapper/CopyToClipboard';
import PopupLinkReferral from '../components/Riffapper/PopupLinkReferral';
import PopupQRReferral from '../components/Riffapper/PopupQRReferral';

const RiffaperProfile = () => {
    const { visible, mostrarMensaje } = useContext(CopiadoContext);
    const [isFirstModalVisible, setFirstModalVisible] = useState(false);
    const [isSecondModalVisible, setSecondModalVisible] = useState(false);
    const [userData, setUserData] = useState({ name: '', plan: '' });
    const openFirstModal = () => {
        setFirstModalVisible(true);
    };
    const closeFirstModal = () => {
        setFirstModalVisible(false);
    };
    const openSecondModal = () => {
        setSecondModalVisible(true);
    };
    const closeSecondModal = () => {
        setSecondModalVisible(false);
    };
    const ocultarMensaje = () => {
        mostrarMensaje(false); // Llama a mostrarMensaje con false para ocultar el mensaje
    };

    const [link, setLink] = useState('');
    const [error, setError] = useState('');
    const [errorUsuario, setErrorUsuario] = useState('');
    const [errorLink, setErrorLink] = useState('');
    const apiURL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const token = localStorage.getItem('token'); // Obtiene el token una sola vez

        if (token) {
            // Ejecuta ambas solicitudes en paralelo
            Promise.all([obtenerDatosUsuario(token), obtenerLink(token)])
                .then(([userResponse, linkResponse]) => {
                    setUserData({ name: userResponse.data.name });
                    setLink(linkResponse.data.referral_link);
                })
                .catch((err) => {
                    console.error("Error en una o ambas solicitudes:", err);
                });
        } else {
            setErrorUsuario('No se encontró el token de autenticación');
            setErrorLink('No se encontró el token de autenticación');
        }
    }, []); // Se ejecuta solo una vez al montar el componente

    const obtenerDatosUsuario = async (token) => {
        try {
            const headers = { Authorization: `Token ${token}` };
            const response = await axios.get(`${apiURL}users/me/`, { headers });

            if (response.status === 200) {
                return response; // Retorna la respuesta para manejarla en Promise.all
            } else {
                setErrorUsuario('Error al obtener los datos del usuario');
                throw new Error('Error al obtener los datos del usuario');
            }
        } catch (error) {
            setErrorUsuario('Error al obtener los datos del usuario');
            throw error; // Lanza el error para que Promise.all lo capture
        }
    };

    const obtenerLink = async (token) => {
        try {
            const headers = { Authorization: `Token ${token}` };
            const response = await axios.get(`${apiURL}users/referral-link/`, { headers });

            if (response.status === 200) {
                return response; // Retorna la respuesta para manejarla en Promise.all
            } else {
                setErrorLink('Error al obtener el link');
                throw new Error('Error al obtener el link');
            }
        } catch (error) {
            setErrorLink('Error al obtener el link');
            throw error; // Lanza el error para que Promise.all lo capture
        }
    };

    return (
        <div>
            <Navbar />
            <section className="bg-yellow">
                <div className="w-layout-blockcontainer container w-container">
                    <div className="w-layout-vflex align-center gap-32 pd-top-80">
                        <div className="w-layout-vflex align-center">
                            <h3 className="text-riffapper">RIFFAPPER</h3>
                            <div className="font-12 font-yellowdark">x</div>
                            <img src="/logo-riffapp.png" loading="lazy" alt="" className="logo-navbar" />
                        </div>
                        <img src="/perfil.jpeg" loading="lazy" alt="" className="img-riffaper" />
                    </div>
                </div>
            </section>
            <section>
                <div className="w-layout-blockcontainer container pd-top-150 pd-bottom-50 w-container">
                    <div className="w-layout-vflex align-center">
                        <h1 className="number-bought">{userData.name || 'Cargando...'}</h1>
                        <div className="font-20">Silver</div>
                    </div>
                </div>
            </section>
            <section>
                <div className="w-layout-blockcontainer container pd-top-32 pd-bottom-32 w-container">
                    <div className="w-layout-vflex container-full-width gap-32">
                            <div className="w-layout-vflex container-link-refeal">
                                <h4>Visualizar Link de Riffapper</h4>
                                <div className="grid-btns-refeal">
                                    <CopyToClipboardLink text={link}/>
                                    
                                    <div onClick={openFirstModal} className="btn-light w-button">Ver Link</div>
                                    <div onClick={openSecondModal} className="btn-light w-button">Ver Código QR</div>
                                </div>
                            </div>
                            {visible && (
                                <div id="copiado-exitoso" className="w-layout-hflex badge-orange">
                                    <div>Link copiado en el portapapeles</div>
                                    <a href="#" onClick={ocultarMensaje}>X</a> {/* Añadir funcionalidad para cerrar */}
                                </div>
                            )}
                       
                    </div>
                </div>
            </section>
            <section>
                <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-32 w-container">
                    <div className="w-layout-vflex gap-32">
                        <div className="w-layout-hflex container-full-width">
                            <div className="w-layout-hflex">
                                <a href="#" className="btn-filter first w-button">12 months</a>
                                <a href="#" className="btn-filter w-button">30 days</a>
                                <a href="#" className="btn-filter w-button">7 days</a>
                                <a href="#" className="btn-filter last w-button">24 hours</a>
                            </div>
                        </div>
                        <div className="w-layout-hflex container-full-width gap-32 strech">
                            <div className="w-layout-vflex gap-8">
                                <div className="font-small font-weight-500">Cantidad de tickets vendidos</div>
                                <div className="w-layout-hflex align-center gap-16">
                                    <div className="number-bought">162</div>
                                    <div className="w-layout-hflex label-analitycs green">
                                        <img src="/Icon-arrow-green.svg" loading="lazy" alt="" />
                                        <div>7.4%</div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/Line-and-bar-chart.svg" loading="lazy" alt="" />
                            </div>
                            <div className="w-layout-vflex gap-20">
                                <div className="w-layout-vflex gap-8">
                                    <div className="font-small font-weight-500">Cantidad de tickets vendidos</div>
                                    <div className="w-layout-hflex align-center gap-16">
                                        <div className="number-bought">162</div>
                                        <div className="w-layout-hflex label-analitycs green">
                                            <img src="/Icon-arrow-green.svg" loading="lazy" alt="" />
                                            <div>9.2%</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-layout-vflex gap-8">
                                    <div className="font-small font-weight-500">Ingresos generados.</div>
                                    <div className="w-layout-hflex align-center gap-16">
                                        <div className="number-bought">2,671</div>
                                        <div className="w-layout-hflex label-analitycs green">
                                            <img src="/Icon-arrow-green.svg" loading="lazy" alt="" />
                                            <div>6.6%</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-layout-vflex gap-8">
                                    <div className="font-small font-weight-500">Total de usuarios afiliados.</div>
                                    <div className="w-layout-hflex align-center gap-16">
                                        <div className="number-bought">82%</div>
                                        <div className="w-layout-hflex label-analitycs green">
                                            <img src="/Icon-arrow-green.svg" loading="lazy" alt="" />
                                            <div>8.1%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-layout-vflex container-full-width gap-32">
                            <div>Top clientes propios</div>
                            <ReferralsClients />
                        </div>
                    </div>
                </div>
            </section>
            {/* Modal */}
            {isFirstModalVisible && (
                <PopupLinkReferral link={link} onClose={closeFirstModal} />
            )}


            {/* Modal */}
            {isSecondModalVisible && (
                <PopupQRReferral link={link} onClose={closeSecondModal} />
            )}
            <Footer />
        </div>

    );
};

export default RiffaperProfile;

import React from 'react';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer';
import ActiveRaffles from '../components/Raffles/ActiveRaffles';
import ActiveRafflesUser from '../components/Raffles/ActiveRafflesUser';
import FinishedRaffles from '../components/Raffles/FinishedRaffles';
import SearchBar from '../components/Search/SearchBar';

const MyRaffles = () => {


  return (
    <div>
      <Navbar />
      <section>
        <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-120 w-container">
          <div className="banner-home mis-rifas">
            <SearchBar />
          </div>
        </div>
      </section>

      <section>
        <div className="w-layout-blockcontainer container pd-bottom-96 w-container">
          <div className="w-layout-vflex container-rifas-calientes">
            <div className="w-layout-vflex div-text-rifas-calientes">
              <h4>Rifas activas</h4>
              <p className="font-medium text-center">
                ¡Muy pronto sabrás si eres el afortunado! La fecha del sorteo está a la vuelta de la esquina.
              </p>
            </div>
            <ActiveRafflesUser />

          </div>
        </div>
      </section>

      <section>
        <div className="w-layout-blockcontainer container pd-bottom-96 w-container">
          <div className="w-layout-vflex container-rifas-calientes">
            <div className="w-layout-vflex div-text-rifas-calientes">
              <h4 className="text-center">Rifas con resultados publicados</h4>
              <p className="font-medium text-center">
                ¡Los resultados están aquí! Verifica si tu número fue el afortunado
              </p>
            </div>
            <FinishedRaffles />
          </div>
        </div>
      </section>
      <Footer />


    </div>
  );
};

export default MyRaffles;

import { useState, useEffect } from 'react';
import axios from 'axios';

const useRaffles = (queryParams) => {
    const [raffles, setRaffles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const apiURL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchRaffles = async () => {
            try {
                const token = localStorage.getItem('token');
                const headers = {};
                
                if (!(queryParams?.is_hot === true || queryParams?.is_hot === 'true')) {
                    headers.Authorization = `Token ${token}`;
                }

                const response = await axios.get(`${apiURL}raffles/`, {
                    headers,
                    params: queryParams,
                });

                setRaffles(response.data.results);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchRaffles();
    }, [queryParams]);

    return { raffles, loading, error };
};

export default useRaffles;
import React, { useState, useEffect } from 'react';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbar from '../components/Navbar/Navbar';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import CountdownTimer from '../components/CountdownTrimer';

const SelectBookRaffle = () => {
    const location = useLocation();
    const { raffleDetail } = location.state || {};
    const navigate = useNavigate();
    const apiURL = process.env.REACT_APP_API_URL;

    const [numbers, setNumbers] = useState([]);
    const [selectedNumbers, setSelectedNumbers] = useState([]);
    const [limitReached, setLimitReached] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        generateRandomNumbers();
    }, []);

    useEffect(() => {
        console.log(selectedNumbers);
    }, [selectedNumbers]);

    const generateRandomNumbers = () => {
        const randomNumbers = Array.from({ length: 10 }, () => Math.floor(Math.random() * 1000) + 1);
        setNumbers(randomNumbers);
        setSelectedNumbers(randomNumbers);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Enviando formulario...");
        console.log("Números seleccionados:", selectedNumbers);
        if (selectedNumbers.length === 0) {
            console.error("No hay números seleccionados.");
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const response = await axios.post(
                `${apiURL}raffles/purchase/`,
                {
                    raffle: raffleDetail.id,
                    selected_numbers: selectedNumbers,
                    terms_accepted: true,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${localStorage.getItem('token')}`,
                    },
                }
            );

            if (response.status === 200) {
                setLimitReached(false);
                navigate('/details-pay-raffle', { state: { raffleDetail, selectedNumbers } });
                const { clientSecret, paymentIntentId } = response.data;
                localStorage.setItem('clientSecret', clientSecret);
                localStorage.setItem('paymentIntentId', paymentIntentId);
            }
        } catch (error) {
            if (error.response?.status === 400) {
                const errorDetail = error.response.data?.error?.non_field_errors || [];
                if (errorDetail.includes("Has alcanzado el límite de compras para esta rifa.")) {
                    setLimitReached(true);
                } else {
                    setError('Hubo un error al realizar la compra. Inténtalo de nuevo.');
                }
            } else {
                setError('Hubo un error al realizar la compra. Inténtalo de nuevo.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleTimeUp = () => {
        alert('El tiempo ha expirado. Por favor, vuelve a generar los números.');
        navigate(`/info-raffle/${raffleDetail.id}`);
    };

    return (
        <div>
            <Navbar />
            <section>
                <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-150 w-container">
                    <div className="grid-select-numbers">
                        <div className="w-layout-vflex gap-32">
                            <h4>{raffleDetail?.title || 'Rifa'}</h4>
                            {limitReached && (
                                <div className="error-message full-width error-big">
                                    Este perfil ha alcanzado el número máximo permitido de tickets para esta rifa. Ya ha seleccionado un total de <strong>10 números</strong>.
                                </div>
                            )}

                            <div className="w-form full-width">
                                <form className="container-tabs-numbers" onSubmit={handleSubmit}>
                                    <div className='w-layout-vflex'>
                                        <p className='font-small font-graydark'>Números de tu talonario</p>
                                        <div className='grid-numbers book full-width'>
                                            {numbers.map((number, index) => (
                                                <label key={index} className="w-checkbox container-checkbox no-pointer no-hover full-width">
                                                    <input
                                                        type="checkbox"
                                                        id={number}
                                                        value={number}
                                                        className="w-checkbox-input w-checkbox-input--inputType-custom check"
                                                    />
                                                    <span className="check">{number}</span>
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                    <button
                                        className="btn-second w-button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            generateRandomNumbers();
                                        }}>
                                        Refrescar
                                    </button>
                                    <div className="w-layout-vflex duv-btn-buy-numbers">
                                        <button
                                            type="submit"
                                            className="btn-second w-button"
                                        >
                                            Comprar Talonario
                                        </button>
                                        <Link to={`/terms-and-conditions/${raffleDetail?.id}`} className="link">
                                            Ver términos y condiciones de esta rifa
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="img-w-border">
                            <img src={`${raffleDetail?.images?.[0]?.image || '/imagen-no-disponible.jpg'}`} loading="lazy" alt="" className="img-w-border" />
                            <div className="container-countdown">
                                <CountdownTimer onTimeUp={handleTimeUp} />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default SelectBookRaffle;

import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";

function Payment({ raffle, selected_numbers}) {
  const [stripePromise, setStripePromise] = useState(null);
  // const [clientSecret, setClientSecret] = useState("");
  const apiURL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');
  console.log(token);
  console.log(selected_numbers);

 const clientSecret =  localStorage.getItem('clientSecret');
 


  useEffect(() => {
    fetch(`${apiURL}config/`)
      .then(async (r) => {
        const { publishableKey } = await r.json();
        setStripePromise(loadStripe(publishableKey));
        localStorage.setItem('publishableKey', publishableKey);
        
      });
  }, []);
  
  return (
    <>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{clientSecret}}>
          <CheckoutForm selectedNumbers={selected_numbers} raffle={raffle}/>
        </Elements>
      )}
    </>
  );
}
export default Payment;

import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import UserContext from '../context/UserContext'; // Importa el contexto
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer';
import LoginGoogle from '../components/Login';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { updateUserInfo } = useContext(UserContext); // Usa el contexto
  const apiURL = process.env.REACT_APP_API_URL;
  const clientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const traducirError = (mensaje) => {
    switch (mensaje) {
      case 'Unable to log in with provided credentials.':
        return 'No se puede iniciar sesión con las credenciales proporcionadas.';
      default:
        return 'Error desconocido.';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${apiURL}auth-token/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Cookie': 'django_language=es-ve',  // Añadir el cookie header si es necesario
        },
        body: JSON.stringify({
          username: email.toLowerCase(),
          password: password
        })
      });

      if (response.ok) {
        const data = await response.json();
        // Guarda el token en localStorage
        localStorage.setItem('token', data.token);
        localStorage.setItem('username', "");
        // Actualiza el contexto de usuario
        updateUserInfo({
          access_token: data.token,
          username: data.name // Supongo que en tu respuesta viene un usuario
        });

        // Redirigir a la página de perfil
        navigate('/home-log-in');
      } else {
        const errorData = await response.json();
        const errorMessage = errorData.detail?.non_field_errors?.[0] || errorData.error || 'Error en la autenticación';
        setError(traducirError(errorMessage));
      }
    } catch (err) {
      console.error('Error al intentar autenticar:', err);
      setError('Error de red. Inténtalo de nuevo más tarde.');
    }
  };

  return (
    <div>
      <Navbar />
      <section>
        <div className="w-layout-hflex container-log">
          <div className="w-layout-vflex container-form-log">
            <div className="w-layout-vflex div-log-in">
              <img src="/logo-riffapp.png" loading="lazy" alt="" className="logo-navbar" />
              <h1 className="font-30">¡Bienvenido!</h1>
              <div className="form-block-log w-form">
                <form
                  className="container-log-fields"
                  onSubmit={handleSubmit}
                >
                  <div className="w-layout-vflex gap-10">
                    <label className="font-small font-graydark">Email</label>
                    <input
                      className="fields w-input"
                      placeholder="Introduce tu email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="w-layout-vflex gap-10">
                    <label htmlFor="Password" className="font-small font-graydark">Contraseña</label>
                    <input
                      className="fields w-input"
                      placeholder=""
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  {error && <div className="error-message"><div>{error}</div></div>}
                  <Link to={{ pathname: "/reset-password", state: { origin: "reset" } }}>Olvidé la contraseña</Link>
                  <input type="submit" className="btn-primary w-button" value="Iniciar sesión" />
                </form>
                {error && <div className="w-form-fail"><div>{error}</div></div>}
              </div>
              <div className='element-center'>
                <GoogleOAuthProvider clientId={clientID}>
                  <LoginGoogle />
                </GoogleOAuthProvider>
              </div>
              <div className="font-small">
                ¿No posees una cuenta? <Link to="/signup">Registrarse</Link>
              </div>
            </div>
          </div>
          <div className="w-layout-vflex container-full-yellow"></div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Login;

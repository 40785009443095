import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer';
import SectionSearch from '../components/Search/SearchSection';
import ResultsPage from "../components/Search/SearchResults";
import axios from 'axios';

const ResultSearchPage = () => {
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const location = useLocation();
    const apiURL = process.env.REACT_APP_API_URL;


    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const query = searchParams.get('search');

        const fetchResults = async () => {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            const headers = token ? { Authorization: `Token ${token}` } : {};
            try {
                
                console.log(query);
                const response = await axios.get(`${apiURL}raffles/?search=${(query)}`, { headers });
                
                console.log(response);
                if (!response=== 200) {
                    console.log(Error('Error al buscar'));
                }
                
                setResults(response.data.results);
                console.log(results);
                setError(null);
            } catch (err) {
                setError(err.message);
                setResults([]);
            } finally {
                setIsLoading(false);
            }
        };
        if (query) {
            fetchResults();
        }
    }, [location.search]);

    // Efecto para manejar el desplazamiento
    useEffect(() => {
        const handleScrollToHash = () => {
            const hash = location.hash.substring(1); // Elimina el "#" del hash
            if (hash) {
                const element = document.getElementById(hash);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }
        };

        handleScrollToHash(); // Ejecuta al montar o cambiar hash
    }, [location.search, location.hash]);

    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('search');

    return (
        <div>
            <Navbar />
            <SectionSearch />
            <section>
                <div className="w-layout-blockcontainer container pd-bottom-96 w-container">
                    <div className="w-layout-vflex container-rifas-calientes">
                        <div className="w-layout-vflex div-text-rifas-calientes" id='resultado'>
                            <div className="font-small _w-bg-yellow">Busqueda</div>
                            <h4>Resultados para: {query}</h4>
                        </div>
                        {isLoading && <p>Cargando...</p>}
                        {error && <p>Error: {error}</p>}
                        <ResultsPage results={results} />
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default ResultSearchPage;
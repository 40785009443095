import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MenuHamburguer from '../MenuHamburguer';
import NotificationsModal from '../Notifications';
import axios from 'axios';

const Navbarlogin = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [userName, setUserName] = useState(''); // Estado para el nombre del usuario
    const [error, setError] = useState('');
    const apiURL = process.env.REACT_APP_API_URL; // Asegúrate de tener la URL de tu API configurada
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [menuOpenMobile, setMenuOpenMobile] = useState(false);

    const toggleMenuMobile = () => {
        setMenuOpen(!menuOpenMobile);
    };

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const closeModal = () => {
        setIsModalOpen(false); // Cierra el modal
    };

    useEffect(() => {
        const storedUserName = localStorage.getItem('username'); // Obtiene el nombre del usuario desde localStorage
        if (storedUserName) {
            setUserName(storedUserName); // Actualiza el estado con el nombre almacenado
        } else {
            obtenerDatosUsuario(); // Llama a la función para obtener los datos del usuario si no hay nombre almacenado
        }
    }, []);

    const obtenerDatosUsuario = async () => {
        const token = localStorage.getItem('token'); // Obtiene el token desde localStorage
        try {
            const headers = {
                Authorization: `Token ${token}`, // Autenticación con el token
            };
            const response = await axios.get(`${apiURL}users/me/`, { headers });
            if (response.status === 200) {
                setUserName(response.data.name); // Actualiza el nombre del usuario con la respuesta
                localStorage.setItem('username', response.data.name);
                localStorage.setItem('userid', response.data.id);
                setError('');
            } else {
                setError('Error al obtener los datos del usuario');
            }
        } catch (error) {
            console.error('Error al obtener los datos del usuario:', error);
            setError('Error al obtener los datos del usuario');
        }
    };

    return (
        <div className="container-navbar w-nav">
            <div className="container navbar w-container">
                <Link to="/home-log-in" className="w-nav-brand">
                    <img src="/logo-riffapp.png" loading="lazy" alt="Logo" className="logo-navbar" />
                </Link>
                {/* Hamburger Button */}
                <div className="w-nav-button" onClick={toggleMenuMobile}>
                    <div className={`w-icon-nav-menu ${menuOpenMobile ? 'open' : ''}`}></div>
                </div>
                {/* Navigation Menu */}
                <nav role="navigation" className="nav-menu w-nav-menu">
                    <div className="w-layout-hflex container-options-menu">
                        <Link to="/home-log-in" className="nav-link w-nav-link">
                            Inicio
                        </Link>
                        <Link to="/my-raffles" className="nav-link w-nav-link">
                            Mis rifas
                        </Link>
                    </div>
                    <div className="w-layout-hflex container-options-menu">
                        <div className="w-layout-hflex container-perfil-nav">
                            {/* Aquí muestra el nombre dinámicamente */}
                            <Link to="/user-profile" className="nav-link w-nav-link">
                                {userName || 'Cargando...'}
                            </Link>
                            <img src="/perfil.jpeg" loading="lazy" alt="" className="img-perfil-nav" />
                        </div>
                        <div className="menu-hamburguer w-inline-block" onClick={toggleMenu}>
                            <img src="/menu-hamburguesa.svg" loading="lazy" alt="Menu" />
                        </div>
                    </div>
                </nav>
            </div>

            {/* Renderiza el componente del menú solo si el menú está abierto */}
            {isMenuOpen && (
                <MenuHamburguer isOpen={isMenuOpen} toggleMenu={toggleMenu} toggleModal={toggleModal} />
            )}
            {isModalOpen && <NotificationsModal closeModal={closeModal} />}
        </div>
    );
};

export default Navbarlogin;

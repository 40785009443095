import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState({ access_token: null, username: null });
  const [loading, setLoading] = useState(true);  // Estado para gestionar la carga inicial
  const apiURL = process.env.REACT_APP_API_URL;

  // Función para verificar el token almacenado
  const verifyToken = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
      setUserInfo({ access_token: null, username: null });
      setLoading(false); // Deja de cargar si no hay token
      return;
    }

    try {
      const headers = { Authorization: `Token ${token}` };
      // Verifica si el token es válido
      const response = await axios.get(`${apiURL}users/me/`, { headers });
      if (response.status === 200) {
        setUserInfo({
          access_token: token,
          username: response.data.name,
        });
        setLoading(false); // Carga completada cuando obtenemos los datos del usuario
      } else {
        setUserInfo({ access_token: null, username: null });
        setLoading(false); // Deja de cargar si el token no es válido
      }
    } catch (error) {
      console.error('Error al obtener los datos del usuario:', error);
      setUserInfo({ access_token: null, username: null });
      setLoading(false); // Deja de cargar si hay un error
    }
  };

  

  // Función para cerrar sesión
  const logout = () => {
    localStorage.clear();
    setUserInfo({ access_token: null, username: null });
  };

  // useEffect para verificar el token cuando el componente se monta
  useEffect(() => {
    verifyToken();
  }, []);

  return (
    <UserContext.Provider value={{ userInfo, updateUserInfo: setUserInfo, logout }}>
      {/* Mientras está cargando, podrías mostrar un loader */}
      {loading ? <div>Cargando...</div> : children}
    </UserContext.Provider>
  );
};

export default UserContext;
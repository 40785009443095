import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from '../context/UserContext'; // Asegúrate de ajustar la ruta al contexto

const ProtectedRoute = ({ children }) => {
  const { userInfo, loading } = useContext(UserContext);

  if (loading) {
    return <div>Cargando...</div>; // Muestra un indicador de carga mientras se verifica el token
  }

  if (!loading && !userInfo.access_token) {
    return <Navigate to="/signup" />; // Redirige al login si no hay token
  }

  // Si hay token, muestra el componente hijo
  return children;
};

export default ProtectedRoute;

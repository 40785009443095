import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';


const Footer = () => {
  return (
    <section>
      <div className="w-layout-blockcontainer container footer w-container">
        <div className="w-layout-hflex container-footer">
          <div className="w-layout-vflex gap-16 items-center-mobile column-about">
            <div className="letter-strong">Acerca de Riffapp</div>
            <p class="font-small copyright">¡Bienvenido a <strong>Riffapp</strong>, la suerte digital al alcance de tus manos!</p>
            <p class="font-small copyright">En Riffapp lideramos el mundo de las <strong>rifas digitales</strong>, ofreciendo una <strong>plataforma segura</strong>, <strong>accesible y emocionante</strong> donde puedes participar para ganar <strong>increíbles premios</strong> con tan solo unos clics. Nuestra misión es transformar la experiencia de las rifas tradicionales en algo <strong>innovador, justo y transparente.</strong></p>
            <p class="font-small copyright">¿Listo para cambiar tu día con un toque de suerte?</p>

          </div>
          <div className="w-layout-hflex container-opc-footer">

            <div className="w-layout-vflex gap-16 items-center-mobile">
              <div className="letter-strong">Contáctanos</div>
              <ScrollLink
                to="newsletter"
                smooth={true}
                duration={500}
                className="pointer">
                Newsletter
              </ScrollLink>
              <RouterLink to="/help">
                Centro de ayuda
              </RouterLink>
              {/* <RouterLink to="/">
                Blog
              </RouterLink>
              <RouterLink to="/">
                Eventos
              </RouterLink>
              <RouterLink to="/">
                Tutoriales
              </RouterLink>
              <RouterLink to="/">
                Soporte
              </RouterLink> */}
            </div>
            <div className="w-layout-vflex gap-16 items-center-mobile">
              <div className="letter-strong">Redes sociales</div>
              <RouterLink to="/">
                Twitter
              </RouterLink>
              <RouterLink to="/">
                LinkedIn
              </RouterLink>
              <RouterLink to="/">
                Facebook
              </RouterLink>
            </div>
            <div className="w-layout-vflex gap-16 items-center-mobile">
              <div className="letter-strong">Legal</div>
              <RouterLink to="/">
                Términos y condiciones
              </RouterLink>
              <RouterLink to="/">
                Políticas de privacidad
              </RouterLink>
              <RouterLink to="/">
                Políticas de cookies
              </RouterLink>
              <RouterLink to="/">
                Licencias
              </RouterLink>
            </div>
          </div>
          <div className="w-layout-vflex gap-16 items-center-mobile download-icons">
            <div className="letter-strong">Descarga</div>
            <img src="/Mobile-app-store-badge.svg" alt="Mobile app store badge" />
            <img src="/Mobile-app-store-badge-1.svg" alt="Mobile app store badge 1" />
          </div>
        </div>
        <div className="w-layout-hflex container-footer after">
          <img src="/logo-riffapp.png" alt="Riffapp logo" className="logo-navbar" />
          <div className="copyright">© 2024 Riffapp. Todos los derechos reservados.</div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ onTimeUp }) => {
    const [timeLeft, setTimeLeft] = useState(10 * 60); // 10 minutos en segundos

    useEffect(() => {
        if (timeLeft <= 0) {
            onTimeUp(); // Llama a la función cuando el tiempo se acabe
            return;
        }

        const timerId = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);

        return () => clearInterval(timerId); // Limpia el intervalo al desmontar
    }, [timeLeft, onTimeUp]);

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div className="timer-container">
            <div className="timer">
            Tiene este tiempo para terminar la compra</div>
            <div><strong>{formatTime(timeLeft)}</strong></div>
        </div>
    );
};

export default CountdownTimer;

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer';
import NumbersList from '../components/Raffles/NumbersSelected';
import axios from 'axios';
import QRCode from "react-qr-code";



const BuySuccess = () => {
    const location = useLocation();
    const { selectedNumbers, raffle } = location.state || [];
    const apiURL = process.env.REACT_APP_API_URL;
    const appURL = process.env.REACT_APP_URL;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [raffleDate, setRaffleDate] = useState(null);
    const [raffleName, setRaffleName] = useState(null);
    const [rafflePrice, setRafflePrice] = useState(null);
    console.log(selectedNumbers);
    console.log(raffle);
    const formattedNumbers = Array.isArray(selectedNumbers)
        ? selectedNumbers.flat().filter(Boolean).join(' - ')
        : 'Ningún número seleccionado';

    const [link, setLink] = useState('');
    const [raffleImage, setRaffleImage] = useState(null);

    useEffect(() => {
        const fetchDetailsRaffle = async () => {
            const token = localStorage.getItem('token');
            const headers = token ? { Authorization: `Token ${token}` } : {};

            try {
                const response = await axios.get(`${apiURL}raffles/${raffle}/`, { headers });

                const { title, end_date, ticket_price, images } = response.data; // Asegúrate de que tu API devuelva estos campos
                setRaffleDate(end_date);
                setRaffleName(title);
                setRafflePrice(ticket_price);
                if (images && images.length >= 1) {
                    setRaffleImage(images[0].image);
                }
                setLink(`${appURL}info-raffle/${raffle}`)

            } catch (error) {
                setError('Error al cargar los detalles de la rifa.');

                console.log(error);
            } finally {
                setLoading(false);
            }
        };

        fetchDetailsRaffle();

    }, []);

    return (
        <div>
            <Navbar />




            <section className='bg-yellow'>
                <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-96 w-container">
                    <div className="w-layout-vflex gap-32 mxw-400">
                        <div class="w-layout-vflex gap-6">
                            <div class="w-layout-vflex card-success">
                                <img src={raffleImage} loading="lazy" alt="" class="img-factura" />
                                <div class="w-layout-vflex full-width text-center align-center gap-16">
                                    <h3 class="font-24">{raffleName}</h3>
                                    <div>Ticket con siguientes números para la rifa: {raffleName}</div>
                                    <div>El sorteo será el <strong>{new Date(raffleDate).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric', })}</strong></div>
                                    <div>valor de <strong>{rafflePrice}$</strong> por ticket</div>
                                </div>
                                <div class="w-layout-vflex full-width text-center align-center gap-16">
                                    <div>NÚMEROS COMPRADOS</div>
                                    <div class="number-bought">{formattedNumbers}</div>
                                </div>
                            </div>
                            <div class="w-layout-vflex card-success">
                                <div className="container-qr">
                                    <QRCode value={link} />
                                </div>
                                <div class="w-layout-vflex full-width text-center align-center gap-16">
                                    <div>Escanear para ir a la descripción de esta rifa</div>
                                </div>
                            </div>
                        </div>
                        <Link to="/my-raffles" className='btn-light full-width w-button'>
                            Finalizar
                        </Link>
                    </div>
                </div>
            </section>
            <Footer />

        </div>


    );
};

export default BuySuccess;

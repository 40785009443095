import React, { useState } from 'react';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFAQ = () => setIsOpen(!isOpen);

  return (
    <div className={`expandable-single contact-page ${isOpen ? 'active' : ''}`}>
      <div className="expandable-top contact-page" onClick={toggleFAQ}>
        <div className="arrow-expand-master">
          <img src="/Icon-cruz-yellow.svg" alt="Expand" />
        </div>
        <h4 className="font-18 weight-500">{question}</h4>
      </div>
      {isOpen && (
        <div className="expandable-bottom contact-page">
          <div className="rich-text-block faq w-richtext">
            <p>{answer}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default FAQItem;

import React, { createContext, useState } from 'react';

export const CopiadoContext = createContext();

export const CopiadoProvider = ({ children }) => {
    const [visible, setVisible] = useState(false);
    const [referralLink, setReferralLink] = useState(''); 

    const mostrarMensaje = () => {
        setVisible(true);
        setTimeout(() => setVisible(false), 5000); // Oculta después de 2 segundos
    };

    const actualizarLink = (link) => {
        setReferralLink(link); // Función para actualizar el link
    };

    return (
        <CopiadoContext.Provider value={{ visible, mostrarMensaje, referralLink, actualizarLink }}>
            {children}
        </CopiadoContext.Provider>
    );
};

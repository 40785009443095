import React from 'react';
import FAQItem from './FAQItem';

const FAQSection = () => {
  const faqData = [
    {
      question: "Is there a free trial available?",
      answer: "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible."
    },
    {
      question: "How do I cancel my subscription?",
      answer: "You can cancel anytime from your account settings."
    },
    // ...otras preguntas
  ];

  return (
    <section>
      <div className="w-layout-blockcontainer container w-container">
        <div className="w-layout-vflex container faqs">
          <div className="w-layout-vflex div-text-rifas-calientes">
            <h4>Preguntas frecuentes</h4>
            <p className="font-medium text-center">
              Explora nuestra sección de preguntas frecuentes y tutoriales para encontrar respuestas rápidas y guías detalladas sobre el uso de Riffapp.
            </p>
          </div>

          <div className="w-layout-vflex container-faqs">
            {faqData.map((item, index) => (
              <FAQItem key={index} question={item.question} answer={item.answer} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;

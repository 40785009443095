import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer';
import FAQSection from '../components/Faq';
import HotRaffles from '../components/Raffles/HotRaffles';
import jsonp from 'jsonp';
import RaffleSlider from '../components/Raffles/HotRafflesSlider';



function App() {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [email, setEmail] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  const onSubmit = e => {
    e.preventDefault();
    const url = 'https://gmail.us10.list-manage.com/subscribe/post-json?u=49e693038e13a377ba91ce56e&amp;id=70296b0e98&amp;f_id=00f8cae5f0';
    jsonp(`${url}&EMAIL=${email}`, { param: 'c' }, (_, data) => {
      const { msg, result } = data
      setResponseMessage(msg);
    });
  };

  return (
    <div>
      <Navbar />

      <section>
        <div className="w-layout-blockcontainer container pd-bottom-96 w-container pd-top-120">
          <div className="banner-home">
            <div className="w-layout-vflex">
              <div className="w-layout-vflex first-div-banner-home" id="newsletter">
                <h1 className="font-light">¡Únete a Nuestra Comunidad!</h1>
                <p className="font-20 font-light">
                  Suscríbete a nuestro newsletter y mantente al día con las últimas novedades, consejos exclusivos, ofertas y recursos que te ayudarán a aprovechar al máximo todo lo que ofrecemos. ¡No te pierdas de nada!
                </p>
                <div className="w-layout-vflex">
                  <form onSubmit={onSubmit} className="container-form-started">
                    <div className="w-layout-vflex gap-6">
                      <input
                        className="fields w-input"
                        type="email"
                        name="EMAIL"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <div className="font-small font-light desktop">
                        Cuidamos tus datos con <Link to="/" className="link-light">política de privacidad</Link>.
                      </div>
                    </div>
                    <button type="submit" className="btn-dark btn-form w-button" value="Get started">Suscribirme</button>
                    <div className="font-small font-light mobile">
                      Cuidamos tus datos con <Link to="/" className="link-light">política de privacidad</Link>.
                    </div>
                  </form>
                  {responseMessage && (
                    <div className="message winner">
                      <div>¡Gracias por suscribirte y bienvenido a nuestra comunidad! 🎉</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-layout-vflex container-gift">
            <h2 className="title-gift">
              Rifa de un coche 0km <span className="font-yellow">Hyundai i20 2024</span>
            </h2>
            <img
              src="/BA90662F-0A2A-4604-97D6-C11D2A816A42_1_201_a.jpeg"
              alt="Hyundai i20 2024"
            />
            <div className="img-valorado">
              <img src="/valorado.svg" alt="Valorado" className="img-circle-home" />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="w-layout-blockcontainer container pd-bottom-96 w-container" id="raffles">
          <div className="w-layout-vflex container-rifas-calientes">
            <div className="w-layout-vflex div-text-rifas-calientes">
              <div className="font-small _w-bg-yellow">Mayor oportunidad de ganar</div>
              <h4>Rifas calientes</h4>
              <p className="font-medium text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit
              </p>
            </div>
            <div className="full-width desktop">
              <HotRaffles />
            </div>

            <div className="full-width mobile">
              <RaffleSlider />
            </div>
          </div>
        </div>
      </section>


      <section className="bg-light-yellow">
        <div className="w-layout-blockcontainer container w-container">
          <div className="w-layout-vflex container-winners">
            <div className="container-tabs w-tabs">
              <div className="container-opc-tabs w-tab-menu">
                <div className={classNames('opc-tab w-inline-block w-tab-link', { 'w--current': activeTab === 'tab1' })}
                  onClick={() => handleTabClick('tab1')}>
                  <div>Ganadores</div>
                </div>
                <div className={classNames('opc-tab w-inline-block w-tab-link', { 'w--current': activeTab === 'tab2' })}
                  onClick={() => handleTabClick('tab2')}>
                  <div>Cómo funciona</div>
                </div>
              </div>
              <div className="w-tab-content">
                {activeTab === 'tab1' && <div data-w-tab="Tab 1" className="w-tab-pane">
                  <div className="w-layout-vflex align-center gap-24 text-center">
                    <h2>Ganadores</h2>
                    <p className="font-20 font-yellow text-center">
                      ¡Prepárate para ganar! ¡Los premios te están esperando!
                    </p>
                  </div>
                </div>
                }
                {activeTab === 'tab2' && <div data-w-tab="Tab 2" className="w-tab-pane">
                  <div className="w-layout-vflex align-center gap-24 text-center">
                    <h2>Cómo funciona</h2>
                    <p className="font-20 font-yellow text-center">
                      ¡Participa en segundos y aumenta tus chances de ganar! ¡Es así de simple!
                    </p>
                  </div>
                </div>
                }
              </div>
            </div>
            <div className="w-layout-hflex container-buttons-center">
              {/* <Link to="/" className="btn-light w-inline-block">
                <img src="/play-circle.svg" loading="lazy" alt="" />
                <div>Demo</div>
              </Link> */}
              <Link to="/signup" className="btn-primary big w-button">
                Registrarte
              </Link>
            </div>
          </div>
        </div>
      </section>


      <section className="bg-line">
        <div className="w-layout-blockcontainer container w-container">
          <div
            data-poster-url="/videos/6009651_4k-Video_Attractive_1920x1080-poster-00001.jpg"
            data-video-urls="/videos/6009651_4k-Video_Attractive_1920x1080-transcode.mp4,/videos/6009651_4k-Video_Attractive_1920x1080-transcode.webm"
            data-autoplay="true"
            data-loop="true"
            data-wf-ignore="true"
            className="video-winners w-background-video w-background-video-atom"
          >
            <video
              id="395057f7-a511-b471-9d37-c10e188fbaa3-video"
              autoPlay
              loop
              style={{ backgroundImage: 'url("/videos/6009651_4k-Video_Attractive_1920x1080-poster-00001.jpg")' }}
              muted
              playsInline
              data-wf-ignore="true"
              data-object-fit="cover"
            >
              <source src="/videos/6009651_4k-Video_Attractive_1920x1080-transcode.mp4" data-wf-ignore="true" />
              <source src="/videos/6009651_4k-Video_Attractive_1920x1080-transcode.webm" data-wf-ignore="true" />
            </video>
          </div>
        </div>
      </section>

      {/* Sección de estadisticas */}
      {/* <section>
        <div className="w-layout-blockcontainer container w-container">
          <div className="w-layout-vflex container-month-winners">
            <div className="w-layout-vflex div-text-rifas-calientes text-center">
              <h4>Ganadores de este mes</h4>
              <p className="font-medium text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </p>
            </div>
            <div className="w-layout-hflex div-numbers-month-winners">
              <div className="w-layout-vflex align-center">
                <h2 className="font-yellow">400+</h2>
                <div className="font-18 weight-600 text-center">
                  Projects completed
                </div>
              </div>
              <div className="w-layout-vflex align-center">
                <h2 className="font-yellow">600%</h2>
                <div className="font-18 weight-600 text-center">
                  Return on investment
                </div>
              </div>
              <div className="w-layout-vflex align-center">
                <h2 className="font-yellow">10k</h2>
                <div className="font-18 weight-600 text-center">
                  Global downloads
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section>
        <div className="w-layout-blockcontainer container pd-bottom-96 w-container">
          <div className="w-layout-vflex container-review-app">
            <div className="w-layout-vflex div-text-rifas-calientes">
              <h4 className="text-center">Con Riffapp, comprar rifas es más sencillo que nunca</h4>
              <p className="font-medium text-center">
              Selecciona tus números de la suerte o adquiere talonarios completos y participa en emocionantes sorteos.
              </p>
            </div>
            <img
              src="Content.png"
              loading="lazy"
              sizes="(max-width: 479px) 87vw, (max-width: 767px) 92vw, (max-width: 991px) 94vw, (max-width: 1280px) 95vw, 1216px"
              srcSet="/Content-p-500.png 500w, /Content-p-800.png 800w, /Content-p-1080.png 1080w, /Content.png 1216w"
              alt=""
            />
            {/* Tres puntos con información relevante */}
            {/* <div className="w-layout-hflex container-puntos-fuertes">
              <div className="w-layout-vflex div-punto-fuerte">
                <div className="font-20 weight-600">Punto fuerte 1</div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
                <a href="#" className="btn-link w-inline-block">
                  <div>Learn more</div>
                  <img src="/arrow-right.svg" loading="lazy" alt="" />
                </a>
              </div>
              <div className="w-layout-vflex div-punto-fuerte">
                <div className="font-20 weight-600">Punto fuerte 2</div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
                <a href="#" className="btn-link w-inline-block">
                  <div>Learn more</div>
                  <img src="/arrow-right.svg" loading="lazy" alt="" />
                </a>
              </div>
              <div className="w-layout-vflex div-punto-fuerte">
                <div className="font-20 weight-600">Punto fuerte 3</div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
                <a href="#" className="btn-link w-inline-block">
                  <div>Learn more</div>
                  <img src="/arrow-right.svg" loading="lazy" alt="" />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <FAQSection />

      {/* Contenedor de logos de proveedores */}
      {/* <section>
        <div className="w-layout-blockcontainer container pd-bottom-96 w-container">
          <div className="w-layout-vflex container-proveedores">
            <div className="font-20 font-ray-dark text-center">Proveedores de premios</div>
            <div className="w-layout-hflex div-logos">
              <img src="/Boltshift-logo.svg" loading="lazy" alt="Boltshift Logo" />
              <img src="/Lightbox-logo.svg" loading="lazy" alt="Lightbox Logo" />
              <img src="/Featherdev-logo.svg" loading="lazy" alt="Featherdev Logo" />
              <img src="/Spherule-logo.svg" loading="lazy" alt="Spherule Logo" />
              <img src="/Globalbank-logo.svg" loading="lazy" alt="Globalbank Logo" />
            </div>
          </div>
        </div>
      </section> */}


      {/* Contenedor de logos de garantizado */}
      {/* <section className="bg-gray">
        <div className="w-layout-blockcontainer container w-container">
          <div className="w-layout-vflex container-garantizantes">
            <div className="font-20 font-ray-dark">Garantizado por</div>
            <div className="w-layout-hflex div-logos">
              <img src="/Boltshift-logo.svg" loading="lazy" alt="Boltshift Logo" />
              <img src="/Featherdev-logo.svg" loading="lazy" alt="Featherdev Logo" />
            </div>
          </div>
        </div>
      </section> */}
      <Footer />


    </div>
  );
}

export default App;


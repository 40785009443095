import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useRaffles from '../../hooks/useRaffles';

function ActiveRaffles() {
    const navigate = useNavigate(); // Hook para navegar a otra ruta
    const queryParams = useMemo(() => ({ status: 0 }), []);
    const { raffles, loading, error } = useRaffles(queryParams);

    const handleRaffleClick = (id) => {
        navigate(`/info-raffle/${id}`); // Redirigir a la página de detalles con el ID de la rifa
    };

    // Función para truncar texto
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    };


    if (loading) return <p>Cargando...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="grid-rifas-calientes">
            {raffles.map((raffle) => (
                <div 
                    key={raffle.id} 
                    className="w-layout-vflex div-rifa-caliente"
                    onClick={() => handleRaffleClick(raffle.id)} 
                    style={{
                        backgroundImage: `url(${raffle?.images?.[0]?.image || '/imagen-no-disponible.jpg'})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    <div className="price-rifa-caliente">${raffle.ticket_price}</div>
                    <div className="w-layout-vflex gap-24 full-width">
                        <div className="w-layout-vflex">
                            <div className="font-20 font-light weight-500">{raffle.title}</div>
                            <div className="font-small font-gray">{truncateText(raffle.description, 100)}</div>
                        </div>
                        <div className="date-mis-rifas">{new Date(raffle.end_date).toLocaleDateString('es-ES', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                        })}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ActiveRaffles;
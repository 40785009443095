import React, { useMemo } from 'react';
import useRaffles from '../../hooks/useRaffles';
import { useNavigate } from 'react-router-dom';

function ListRaffles() {
  const queryParams = useMemo(() => ({ status: 0 }), []);
  const navigate = useNavigate();
  const { raffles, loading, error } = useRaffles(queryParams);

  const handleRaffleClick = (id) => {
    navigate(`/info-raffle/${id}`); // Redirigir a la página de detalles con el ID de la rifa
  };

  // Función para truncar texto
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
    }
    return text;
};

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="w-layout-hflex grid-rifas">
      {raffles.map((raffle) => (
        <div key={raffle.id} className="w-layout-vflex div-punto-fuerte">
          <div className="div-img-rifa">
            <img
              src={`${raffle?.images?.[0]?.image || '/imagen-no-disponible.jpg'}`}
              loading="lazy"
              alt=""
              class="img-factura"
            />
          </div>
          <div className="font-20 weight-600">{raffle.title}</div>
          <div className="price-listado-rifa">${raffle.ticket_price}</div>
          <p>{truncateText(raffle.description, 100)}</p>
          <div className="btn-link w-inline-block pointer" onClick={() => handleRaffleClick(raffle.id)}>
            <div>Ver más</div>
            <img src="/arrow-right.svg" loading="lazy" alt="" />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ListRaffles;
import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const NewPassword = ({ onNext, code }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [confirmError, setConfirmError] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false); // Estado para controlar la visibilidad del modal
  const apiURL = process.env.REACT_APP_API_URL;

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handlePasswordBlur = () => {
    // Validar que la contraseña tenga al menos 8 caracteres
    if (password.length < 8) {
      setError('La contraseña debe tener al menos 8 caracteres');
    } else {
      setError('');
    }
  };

  const handleConfirmPasswordBlur = () => {
    // Verificar si las contraseñas coinciden
    if (confirmPassword !== password) {
      setConfirmError('No coincide con la contraseña principal');
    } else {
      setConfirmError('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setConfirmError('');
    
    if (password.length < 8) {
      setError('La contraseña debe tener al menos 8 caracteres');
      return;
    }
    
    if (password !== confirmPassword) {
      setConfirmError('Las contraseñas no coinciden');
      return;
    }
    
    setLoading(true);
    
    try {
      const response = await axios.post(
        `${apiURL}password-reset-confirm/`,
        {
          new_password: password,
          confirm_password: confirmPassword,
          code:code,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        // Si la solicitud es exitosa, muestra el modal
        setModalVisible(true);
      }
    } catch (err) {
      if (err.response) {
        setError(err.response.data.detail || 'Error al cambiar la contraseña.');
      } else {
        setError('Error de red. Inténtalo de nuevo más tarde.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="w-layout-vflex div-log-in text-center">
        <h1 className="font-30">Crear nueva contraseña</h1>
        <p>Su nueva contraseña debe ser única de las utilizadas anteriormente.</p>
        <div className="form-block-log w-form">
          <form className="container-log-fields" onSubmit={handleSubmit}>
            <div className="w-layout-vflex gap-10">
              <input
                className="fields w-input"
                placeholder="Introduce una contraseña"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                onBlur={handlePasswordBlur}
                required
              />
              {error && <div className="error-message">{error}</div>}
            </div>
            <div className="w-layout-vflex gap-10">
              <input
                className="fields w-input"
                placeholder="Introduzca nuevamente la contraseña"
                type="password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                onBlur={handleConfirmPasswordBlur}
                required
              />
              {confirmError && <div className="error-message">{confirmError}</div>}
            </div>
            <input
              type="submit"
              data-wait="Please wait..."
              className="btn-primary w-button"
              value={loading ? 'Enviando...' : 'Enviar'}
              disabled={loading}
            />
          </form>
        </div>
      </div>

      {/* Modal */}
      {modalVisible && (
        <div
          className="bg-modal"
          style={{ visibility: modalVisible ? 'visible' : 'hidden', opacity: modalVisible ? 1 : 0, transition: 'opacity 0.5s ease' }}
        >
          <div className="modal-signup">
            <img src="/logo-riffapp.png" loading="lazy" alt="" className="logo-navbar centrado" />
            <div className="w-layout-vflex gap-32 full-width">
              <div className="close-btn ami">
                <Link to="/login">
                  <img loading="lazy" src="/btn-close-modal-ami.svg" alt="Close Button" />
                </Link>
              </div>
              <div className="w-layout-vflex gap-16">
                <img loading="lazy" src="/icon-success.svg" alt="Success Icon" />
                <div className="w-layout-vflex">
                  <h4 className="font-18 weight-600">¡Contraseña cambiada!</h4>
                  <div>Tu contraseña ha sido cambiada exitosamente.</div>
                </div>
              </div>
              <Link to="/login" className="btn-primary full-width w-button">
                Regresar al inicio
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewPassword;


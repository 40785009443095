import React from 'react';

const ContactForm = () => {
  return (
    <div className="w-layout-vflex container-contact-form pd-top-120">
      <div className="w-layout-vflex container-form-contact">
        <div className="w-layout-vflex gap-20">
          <h4>Contáctanos</h4>
          <p className="font-20 font-graydark">En Riffapp, estamos aquí para ayudarte a resolver cualquier duda o inquietud que tengas.</p>
        </div>
        <div className="form-block-log w-form">
          <form className="container-log-fields">
            <div className="grid-field-contact">
              <div className="w-layout-vflex gap-10">
                <label className="font-small font-graydark">Nombre</label>
                <input className="fields w-input" type="text" placeholder="Nombre" />
              </div>
              <div className="w-layout-vflex gap-10">
                <label className="font-small font-graydark">Apellido</label>
                <input className="fields w-input" type="text" placeholder="Apellido" />
              </div>
            </div>
            <div className="w-layout-vflex gap-10">
              <label className="font-small">Email</label>
              <input className="fields w-input" type="email" placeholder="nombre@empresa.com" />
            </div>
            <div className="w-layout-vflex gap-10">
              <label className="font-small font-graydark">Teléfono</label>
              <input className="fields w-input" type="tel" placeholder="+58 000-0000" />
            </div>
            <div className="w-layout-vflex gap-10">
              <label className="font-small font-graydark">Mensaje</label>
              <textarea className="fields w-input" placeholder="Escribe tu mensaje..."></textarea>
            </div>
            <input type="submit" value="Enviar" className="btn-primary w-button" />
          </form>

          <div className="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div className="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;

import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const CodeVerification = ({ onNext, email }) => {
  const [codes, setCodes] = useState(['', '', '', '']);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const apiURL = process.env.REACT_APP_API_URL;

  // Validar que solo sean números y longitud correcta
  const handleChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      const newCodes = [...codes];
      newCodes[index] = value;
      setCodes(newCodes);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    const combinedCode = codes.join('');

    if (combinedCode.length !== 4) {
      setError('El código debe tener 4 dígitos.');
      return;
    }

    if (!email) {
      setError('El correo electrónico es requerido.');
      return;
    }

    setLoading(true);
    
    try {
      const response = await axios.post(
        `${apiURL}verify-reset-code/`,
        {
          email,
          code: combinedCode,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        // Si la verificación es exitosa, pasa al siguiente paso
        onNext(combinedCode); // Asegúrate de que el backend devuelva el código de verificación
      }
    } catch (err) {
      if (err.response) {
        setError(err.response.data.detail || 'Error al verificar el código.');
      } else {
        setError('Error de red. Inténtalo de nuevo más tarde.');
      }
    } finally {
      setLoading(false);
    }
  };

  const renderInputFields = () => {
    return codes.map((code, index) => (
      <input
        key={index}
        className="fields field-code w-input"
        type="text"
        value={code}
        maxLength={1}
        onChange={(e) => handleChange(index, e.target.value)}
        required
      />
    ));
  };

  return (
    <div className="w-layout-vflex div-log-in text-center">
      <p>Ingrese el código de verificación que acabamos de enviar a su dirección de correo electrónico.</p>
      <div className="form-block-log w-form">
        <form className="container-log-fields" onSubmit={handleSubmit}>
          <div className="w-layout-vflex div-code-password">
            {renderInputFields()}
          </div>
          <input
            type="submit"
            data-wait="Please wait..."
            className="btn-primary w-button"
            value={loading ? 'Verificando...' : 'Verificar'}
            disabled={loading}
          />
        </form>
        {error && <div className="w-form-fail"><div>{error}</div></div>}
      </div>
      <div className="font-small">
        ¿No recibiste el código? <Link to="/login">Reenviar</Link>
      </div>
    </div>
  );
};

export default CodeVerification;

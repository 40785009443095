import React, { useState } from 'react';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Footer from '../components/Footer';
import Navbar from '../components/Navbar/Navbar';
import ContactInfo from '../components/Contact/ContactSection';
import ContactForm from '../components/Contact/ContactForm';
import FAQSection from '../components/Contact/FAQSection';

const ContactPage = () => {
    return (
        <div>
            <Navbar/>
            <section>
                <div className="w-layout-blockcontainer container-help w-container">
                    <ContactInfo />
                    <ContactForm />
                </div>
            </section>
            <FAQSection />
            <Footer/>
        </div>


    );
};

export default ContactPage;

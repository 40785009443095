import React from 'react';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer';
import HotRaffles from '../components/Raffles/HotRaffles';
import SearchSection from '../components/Search/SearchSection';
import CTARiffaper from '../components/CTARffapper';
import ListRaffles from '../components/Raffles/ListRaffles';
import RaffleSlider from '../components/Raffles/HotRafflesSlider';

function MyProfile() {

  return (
    <div>
      <Navbar />
      <SearchSection />

      <section>
        <div className="w-layout-blockcontainer container pd-bottom-96 w-container">
          <div className="w-layout-vflex container-rifas-calientes">
            <div className="w-layout-vflex div-text-rifas-calientes">
              <div className="font-small _w-bg-yellow">Populares</div>
              <h4>Rifas calientes</h4>
              <p className="font-medium text-center">
              ¿Quieres saber cuáles son las rifas que todos están jugando?
              <br></br>
              Descubre por qué estas rifas son las favoritas y únete a la emoción.
              </p>
            </div>
            <div className="full-width desktop">
              <HotRaffles />
            </div>

            <div className="full-width mobile">
              <RaffleSlider />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="w-layout-blockcontainer container pd-bottom-96 w-container">
          <div className="w-layout-vflex container-rifas-calientes">
            <div className="w-layout-vflex div-text-rifas-calientes">
              <div className="font-small _w-bg-yellow">Mayor oportunidad de ganar</div>
              <h4>Rifas activas</h4>
              <p className="font-medium text-center">
              Explora nuestra variedad de rifas activas y encuentra la que más se adapte a tus gustos. Cada rifa es una nueva chance de llevarte un premio increíble.
              </p>
            </div>
            <ListRaffles/>
          </div>
        </div>
      </section>

      {/* Logos de garantizado por */}
     {/*  <section>
        <div className="w-layout-blockcontainer container pd-bottom-96 w-container">
          <div className="w-layout-vflex container-proveedores">
            <div className="font-20 font-ray-dark">Garantizado por</div>
            <div className="w-layout-hflex div-logos">
              {[
                'Boltshift-logo.svg',
                'Lightbox-logo.svg',
                'Featherdev-logo.svg',
                'Spherule-logo.svg',
                'Globalbank-logo.svg',
              ].map((logo, index) => (
                <img key={index} src={`/${logo}`} loading="lazy" alt="" />
              ))}
            </div>
          </div>
        </div>
      </section> */}
      
      <Footer />

    </div>
  );
}

export default MyProfile;
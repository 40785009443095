import React, { useState } from 'react';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer';
import ResetRequest from '../components/NewPassword/ResetRequest';
import CodeVerification from '../components/NewPassword/CodeVerification';
import NewPassword from '../components/NewPassword/NewPassword';

function ResetPassword() {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');

    const handleEmailSubmission = (submittedEmail) => {
        setEmail(submittedEmail);
        setStep(2);
    };

    const handleCodeSubmission = (code) => {
        setVerificationCode(code);
        setStep(3);
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return <ResetRequest onNext={handleEmailSubmission} />;
            case 2:
                return <CodeVerification email={email} onNext={handleCodeSubmission} />;
            case 3:
                return <NewPassword email={email} code={verificationCode} onNext={() => setStep(1)} />;
            default:
                return <ResetRequest onNext={handleEmailSubmission} />;
        }
    };

    return (
        <div>
            <Navbar />
            <section>
                <div className="w-layout-hflex container-log">
                    <div className="w-layout-vflex container-form-log">
                        <div className="w-layout-vflex div-log-in">
                            <img src="/logo-riffapp.png" loading="lazy" alt="" className="logo-navbar" />
                            {renderStep()}
                        </div>
                    </div>
                    <div className="w-layout-vflex container-full-yellow"></div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default ResetPassword;
